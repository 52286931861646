export const COLORS = {
  HOLLY_GREEN: "#102721",
  PINE_GREEN: "#07261f",
  BOTTLE_GREEN: "#083025",
  EMPEROR: "#515050",
  CHARCOAL: "#454545",
  BLUE_CHALK_VIOLET: "#D9B5D6",
  CHALK_VIOLET: "#f7edf7",
  AQUA_HAZE: "#F0F5F7",
  BLACK: "#000",
  WHITE: "#FFFFFF",
  FANTASY_WHITE: "#FCF8F5",
  BEIGE: "#F9EDE4",
  SEASHELL: "#fcf8f5",
  PINE: "#23463a",
  CLAY_BROWN: "#bd8f3c",
  SANDY_BROWN: "#c6a56b",
  LIGHT_BLUE_GREY: "#c7dce6",
  PALE_GREY: "#f0f5f7",
  PALE_GREY_2: "#e3edf2",
  PINK: "#ead2e8",
}

export const FONTS = {
  FIFTH_AVE_FONT_FAMILY: "FifthAvenueSans",
  SOHNE_FONT_FAMILY: "Sohne",
  SOHNE_BUCH: "Sohne-Buch",
  SOHNE_HALBFETT: "Sohne-Halbfett",
  SOHNE_LEICHT: "Sohne-Leicht",
  LORA: "Lora",
  FIFTH_AVE_FONT_SPACING: "0.15rem;",
  SIGNIFIER_REGULAR_FONT_FAMILY: "Signifier-Regular",

  SUBTITLE_FONT_FAMILY: "Muli",
  SUBTITLE_FONT_WEIGHT: 900,
  DESKTOP_SUBTITLE_FONT_SIZE: "1rem",
  IPAD_SUBTITLE_FONT_SIZE: "0.9rem",
  MOBILE_SUBTITLE_FONT_SIZE: "1.06rem",

  TITLE_FONT_FAMILY: "Sohne",
  DESKTOP_TITLE_FONT_SIZE: "2.4rem",
  IPAD_TITLE_FONT_SIZE: "1.7rem",
  MOBILE_TITLE_FONT_SIZE: "1.28rem",

  LINK_FONT_FAMILY: "Muli",
  DESKTOP_LINK_FONT_SIZE: "0.96rem",
  IPAD_LINK_FONT_SIZE: "0.67rem",

  RETAILER_CONTACT_FONT_FAMILY: "Muli",
  DESKTOP_RETAILER_CONTACT_FONT_SIZE: "0.96rem",

  DESKTOP_FOOTER_TEXT_FONT_FAMILY: "Muli",
  DESKTOP_FOOTER_TEXT_FONT_SIZE: "0.96rem",
  DESKTOP_FOOTER_TEXT_LINE_HEIGHT: "1.2rem",
  IPAD_FOOTER_TEXT_FONT_SIZE: "0.67rem",
  IPAD_FOOTER_TEXT_LINE_HEIGHT: 0.83,
}

export const MOBILE_MAX_WIDTH = 1000
export const IPAD_MINI_MAX_WIDTH = 700
export const IPHONE5_MAX_WIDTH = 320
export const MAX_WIDTH_LAYOUT = 1920
export const IPAD_MAX_WIDTH = 1400
export const MOBILE_SIDE_MARGIN = 30
export const DESKTOP_BANNER_WIDTH = 1280
export const SM_BREAKPOINT = 768
export const MD_BREAKPOINT = 1000
export const LG_BREAKPOINT = 1200

export const Z_INDEXES = {
  SEARCH_CONTAINER: 5,
  MENU_CONTAINER: 5,
  INDEX_CATEGORY_BACKGROUND: 2,
  INDEX_CATEGORY_BACKGROUND_IMAGE: 1,
  CATEGORY_TITLE_CONTAINER: 2,
  CATEGORY_IMAGE: 1,
  RETAILER_CENTRAL_IMAGE: 2,
  INSTA_CAROUSEL_ARROW: 1,
}
