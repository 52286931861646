// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-at-home-tsx": () => import("./../../../src/pages/atHome.tsx" /* webpackChunkName: "component---src-pages-at-home-tsx" */),
  "component---src-pages-holiday-tsx": () => import("./../../../src/pages/holiday.tsx" /* webpackChunkName: "component---src-pages-holiday-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-itineraries-tsx": () => import("./../../../src/pages/itineraries.tsx" /* webpackChunkName: "component---src-pages-itineraries-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-nyfw-tsx": () => import("./../../../src/pages/nyfw.tsx" /* webpackChunkName: "component---src-pages-nyfw-tsx" */),
  "component---src-pages-special-events-tsx": () => import("./../../../src/pages/special-events.tsx" /* webpackChunkName: "component---src-pages-special-events-tsx" */),
  "component---src-templates-category-category-tsx": () => import("./../../../src/templates/category/category.tsx" /* webpackChunkName: "component---src-templates-category-category-tsx" */),
  "component---src-templates-event-all-events-tsx": () => import("./../../../src/templates/event/allEvents.tsx" /* webpackChunkName: "component---src-templates-event-all-events-tsx" */),
  "component---src-templates-event-event-tsx": () => import("./../../../src/templates/event/event.tsx" /* webpackChunkName: "component---src-templates-event-event-tsx" */),
  "component---src-templates-itinerary-itinerary-tsx": () => import("./../../../src/templates/itinerary/itinerary.tsx" /* webpackChunkName: "component---src-templates-itinerary-itinerary-tsx" */),
  "component---src-templates-retailer-retailer-tsx": () => import("./../../../src/templates/retailer/retailer.tsx" /* webpackChunkName: "component---src-templates-retailer-retailer-tsx" */),
  "component---src-templates-special-special-tsx": () => import("./../../../src/templates/special/special.tsx" /* webpackChunkName: "component---src-templates-special-special-tsx" */)
}

