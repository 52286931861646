import { createMedia } from "@artsy/fresnel"
import { LG_BREAKPOINT, MD_BREAKPOINT, SM_BREAKPOINT } from "./styles"

const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm: SM_BREAKPOINT,
    md: MD_BREAKPOINT,
    lg: LG_BREAKPOINT,
  },
})

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = AppMedia
